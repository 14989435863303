import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../../components/layout'
import Container from '../../components/container'
import PageTitle from '../../components/page-title'
import TarrifsSubNavigation from '../../components/tarrifs-sub-navigation'
import MetaTitle from '../../components/meta-title'
import MetaDescription from '../../components/meta-description'
import PhotoShootPackage from '../../components/photo-shoot-package'

export default function TarievenPakkettenTemplate ({ pageContext }) {
  const {
    metaTitle,
    metaDescription,
    packages,
    extraInfo
  } = pageContext

  console.log(extraInfo)

  const vatMessageClasses = 'block text-12 font-sans text-center text-gray-dark mt-40 leading-tight'

  return (
    <Layout>
      <MetaTitle>{metaTitle}</MetaTitle>
      <MetaDescription>{metaDescription}</MetaDescription>

      <div className='container-wide pt-40'>
        <PageTitle className='mb-40'>Tarieven</PageTitle>
        <TarrifsSubNavigation />
      </div>

      <Container className='pb-40'>
        <div className='flex-col items-center md:flex-row flex md:items-stretch md:justify-around -mx-8 mb-32'>
          {packages.map(({ name, description, photoUrl }) => (
            <PhotoShootPackage
              packageName={name}
              packageDescription={description}
              photoUrl={photoUrl}
              className='mb-16 md:mb-0 max-w-464 mx-8'
              key={name}
            />
          ))}
        </div>
        <div>
          <h2 className='text-16 font-semibold mb-24 text-center uppercase'>Extra Info</h2>
          <div
            className='markdown-paragraphs max-w-600 mx-auto'
            dangerouslySetInnerHTML={{ __html: extraInfo }}
          />
        </div>
        <small className={vatMessageClasses}>
          Deze onderneming is onderworpen aan de bijzondere BTW
          vrijstellingsregeling kleine ondernemingen.
        </small>
      </Container>
    </Layout>
  )
}

TarievenPakkettenTemplate.propTypes = {
  pageContext: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    packages: PropTypes.array,
    extraInfo: PropTypes.node
  })
}
