import React from 'react'
import PropTypes from 'prop-types'

export default function PhotoShootPackage ({ packageName, packageDescription, photoUrl, ...props }) {
  return (
    <div {...props}>
      <img src={photoUrl} />
      <div className='border border-t-0 p-16'>
        <h1 className='mb-24 text-26 font-semibold text-center uppercase'>{packageName}</h1>
        <div
          className='markdown-content text-center'
          dangerouslySetInnerHTML={{ __html: packageDescription }}
        />
      </div>
    </div>
  )
}

PhotoShootPackage.propTypes = {
  packageName: PropTypes.string.isRequired,
  packageDescription: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired
}
